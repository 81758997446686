var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-data-table', {
    staticClass: "table-section text-no-wrap",
    attrs: {
      "headers": _vm.columns,
      "items": _vm.users,
      "options": _vm.options,
      "server-items-length": _vm.usersTotal,
      "loading": _vm.loading,
      "header-props": _vm.headerprops
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.options = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "item.id",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_vm._v(" #" + _vm._s(item.id) + " ")];
      }
    }, {
      key: "item.email",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(item.name) + " "), _c('br'), _c('router-link', {
          attrs: {
            "to": {
              name: 'user-overview',
              params: {
                id: item.id
              }
            }
          }
        }, [_vm._v(" " + _vm._s(item.email) + " ")])];
      }
    }, {
      key: "item.parent_id",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [item.parent_id ? _c('div', [_vm._v(" " + _vm._s(item.parent.name)), _c('br'), _c('router-link', {
          attrs: {
            "to": {
              name: 'user-overview',
              params: {
                id: item.id
              }
            }
          }
        }, [_vm._v(" " + _vm._s(item.parent.email) + " ")])], 1) : _c('div', [_c('span', [_vm._v("-")])])];
      }
    }, {
      key: "item.team_id",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [item.team ? _c('router-link', {
          staticClass: "font-weight-medium text-decoration-none",
          attrs: {
            "to": {
              name: 'team-view',
              params: {
                id: item.team_id
              }
            }
          }
        }, [_vm._v(" " + _vm._s(item.team.nicename) + " ")]) : _vm._e(), _vm._v(" ("), item.team ? _c('router-link', {
          staticClass: "font-weight-medium text-decoration-none",
          attrs: {
            "to": {
              name: 'distributor-view',
              params: {
                id: item.team.distributor.id
              }
            }
          }
        }, [_vm._v(" " + _vm._s(item.team.distributor.nicename) + " ")]) : _vm._e(), _vm._v(") ")];
      }
    }, {
      key: "item.role",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('span', {
          staticClass: "text-no-wrap"
        }, _vm._l(item.roles, function (role) {
          return _c('v-chip', {
            key: role.name
          }, [_vm._v(" " + _vm._s(_vm.t(role.name_translation)) + " ")]);
        }), 1)];
      }
    }, {
      key: "item.created_at",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('span', {
          staticClass: "text-no-wrap"
        }, [_vm._v(_vm._s(_vm.formatDate(item.created_at)))])];
      }
    }, {
      key: "item.actions",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_c('div', {
          staticClass: "d-flex align-center justify-center"
        }, [_c('v-menu', {
          attrs: {
            "bottom": "",
            "left": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref8) {
              var on = _ref8.on,
                attrs = _ref8.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "small": "",
                  "icon": ""
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', {
                attrs: {
                  "size": "18"
                }
              }, [_vm._v(" " + _vm._s(_vm.icons.mdiDotsVertical) + " ")])], 1)];
            }
          }], null, true)
        }, [_c('v-list', [_c('v-list-item', {
          attrs: {
            "link": ""
          }
        }, [_c('v-list-item-title', [_c('edit-dialog', {
          attrs: {
            "user": item
          },
          on: {
            "updated": _vm.onUserUpdated
          }
        })], 1)], 1), _c('v-list-item', {
          attrs: {
            "link": ""
          },
          on: {
            "click": function click($event) {
              return _vm.sync(item);
            }
          }
        }, [_c('v-list-item-title', [_c('v-icon', {
          staticClass: "me-2",
          attrs: {
            "size": "18"
          }
        }, [_vm._v(" " + _vm._s(_vm.icons.mdiSync) + " ")]), _c('span', [_vm._v(_vm._s(_vm.$t('Sync')))])], 1)], 1)], 1)], 1)], 1)];
      }
    }], null, true)
  });
}
var staticRenderFns = []

export { render, staticRenderFns }