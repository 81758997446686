<template>
  <div>
    <filter-section :cols="[3,3,3,3]">
      <search-filter
        slot="col_0"
        v-model="filters"
      />
      <distributor-id-filter
        slot="col_1"
        v-model="filters"
      />
      <team-id-filter
        slot="col_2"
        v-model="filters"
        :distributor-id="filters.distributor_id"
      />
      <user-role-id-filter
        slot="col_3"
        v-model="filters"
      />
    </filter-section>

    <v-card class="mt-3">
      <v-card-text class="d-flex justify-space-between">
        <div>
          <v-btn
            color="primary"
            :loading="loading"
            :disabled="loading"
            @click="fetchData"
          >
            <v-icon class="prefix-icon">
              {{ icons.mdiRefresh }}
            </v-icon>
            <span>
              {{ $t('common.list.refresh') }}
            </span>
          </v-btn>
        </div>

        <div>
          <create-dialog
            v-if="$can('user:create', 'user_management')"
          />

          <v-btn
            v-if="$can('user:export', 'user_management')"
            color="primary"
            @click="exportToCsv"
          >
            <v-icon class="prefix-icon">
              {{ icons.mdiDatabaseExportOutline }}
            </v-icon>
            {{ $t('common.list.export') }}
          </v-btn>
        </div>
      </v-card-text>

      <user-table
        ref="tableRef"
        :loading="loading"
        :users="users"
        :users-total="usersTotal"
        @user-updated="fetchData"
        @options-updated="onOptionsUpdated"
      />
    </v-card>
  </div>
</template>

<script>
import { mdiDatabaseExportOutline, mdiRefresh } from '@mdi/js'

import { t } from '@/plugins/i18n'
import { ref, watch } from '@vue/composition-api'

import { DistributorIdFilter, FilterSection, SearchFilter, TeamIdFilter, UserRoleIdFilter } from '@/components'
import { useOldDataFetch, useOldTableList } from '@/composables'
import { debounce } from 'lodash'
import CreateDialog from '../components/list/CreateDialog.vue'
import UserTable from '../components/list/UserTable.vue'
import { userService } from '../services'

export default {
  components: {
    DistributorIdFilter,
    FilterSection,
    SearchFilter,
    TeamIdFilter,
    UserRoleIdFilter,
    CreateDialog,
    UserTable,
  },
  setup() {
    const { parseParam } = useOldDataFetch()
    const { loading, tableRef, mergeOptionToParams } = useOldTableList()

    const users = ref([])
    const usersTotal = ref(0)

    const filters = ref({
      search: null,
      distributor_id: null,
      team_id: null,
      role_id: null,
    })

    // Merge filter to params as default query params
    const params = ref({
      ...filters.value,
    })

    const fetchData = async () => {
      loading.value = true

      try {
        const { records, pagination } = await userService.index(parseParam(params.value))

        users.value = records
        usersTotal.value = pagination.total
      } catch (error) {
        console.log(error)
      } finally {
        loading.value = false
      }
    }

    const onFilterUpdated = value => {
      params.value = {
        ...params.value,
        ...value,
      }

      fetchData()
    }

    watch(filters, debounce(onFilterUpdated, 300))

    const onOptionsUpdated = options => {
      params.value = mergeOptionToParams(options, params.value)

      fetchData()
    }

    const exportToCsv = async () => {
      loading.value = true

      try {
        await userService.exportUsers(parseParam(params.value))
      } catch (error) {
        console.log(error)
      } finally {
        loading.value = false
      }
    }

    return {
      t,
      loading,

      tableRef,

      filters,
      users,
      usersTotal,

      fetchData,
      onFilterUpdated,
      onOptionsUpdated,

      exportToCsv,

      icons: {
        mdiRefresh,
        mdiDatabaseExportOutline,
      },
    }
  },
}
</script>
