<template>
  <v-data-table
    :headers="columns"
    :items="users"
    :options.sync="options"
    :server-items-length="usersTotal"
    :loading="loading"
    class="table-section text-no-wrap"
    :header-props="headerprops"
  >
    <!-- ID -->
    <template #[`item.id`]="{ item }">
      #{{ item.id }}
    </template>

    <!-- Email -->
    <template #[`item.email`]="{ item }">
      {{ item.name }} <br>
      <router-link :to="{ name: 'user-overview', params: { id: item.id } }">
        {{ item.email }}
      </router-link>
    </template>

    <!-- parent -->
    <template #[`item.parent_id`]="{ item }">
      <div v-if="item.parent_id">
        {{ item.parent.name }}<br>
        <router-link :to="{ name: 'user-overview', params: { id: item.id } }">
          {{ item.parent.email }}
        </router-link>
      </div>

      <div v-else>
        <span>-</span>
      </div>
    </template>

    <!-- team -->
    <template #[`item.team_id`]="{ item }">
      <router-link
        v-if="item.team"
        class="font-weight-medium text-decoration-none"
        :to="{ name: 'team-view', params: { id: item.team_id } }"
      >
        {{ item.team.nicename }}
      </router-link>

      (<router-link
        v-if="item.team"
        class="font-weight-medium text-decoration-none"
        :to="{ name: 'distributor-view', params: { id: item.team.distributor.id } }"
      >
        {{ item.team.distributor.nicename }}
      </router-link>)
    </template>

    <!-- role -->
    <template #[`item.role`]="{ item }">
      <span class="text-no-wrap">
        <v-chip
          v-for="role in item.roles"
          :key="role.name"
        >
          {{ t(role.name_translation) }}
        </v-chip>
      </span>
    </template>

    <!-- date -->
    <template #[`item.created_at`]="{ item }">
      <span class="text-no-wrap">{{ formatDate(item.created_at) }}</span>
    </template>

    <!-- actions -->
    <template #[`item.actions`]="{ item }">
      <div class="d-flex align-center justify-center">
        <!-- dropdown -->
        <v-menu
          bottom
          left
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon size="18">
                {{ icons.mdiDotsVertical }}
              </v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              link
            >
              <v-list-item-title>
                <edit-dialog :user="item" @updated="onUserUpdated" />
              </v-list-item-title>
            </v-list-item>

            <v-list-item
              link
              @click="sync(item)"
            >
              <v-list-item-title>
                <v-icon
                  size="18"
                  class="me-2"
                >
                  {{ icons.mdiSync }}
                </v-icon>
                <span>{{ $t('Sync') }}</span>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import { useNotifySuccess, useOldDataTable } from '@/composables'
import { t } from '@/plugins/i18n'
import { formatDate } from '@core/utils/filter'
import { mdiDotsVertical, mdiPencilOutline, mdiSync } from '@mdi/js'
import { ref, watch } from '@vue/composition-api'
import { userService } from '../../services'
import EditDialog from './EditDialog.vue'

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    users: {
      type: Array,
      default: () => [],
    },
    usersTotal: {
      type: Number,
      default: 0,
    },
  },
  components: {
    EditDialog,
  },
  setup(props, { emit }) {
    const { isPageManualChange, defaultOptions, headerprops, resetPage } = useOldDataTable()

    const options = ref({
      ...defaultOptions,
    })

    const columns = [
      { text: 'ID', value: 'id' },
      { text: 'Email', value: 'email' },
      { text: 'Parent User', value: 'parent_id' },
      { text: 'Team', value: 'team_id' },
      { text: 'Role', value: 'role', sortable: false },
      { text: 'REG DATE', value: 'created_at' },
      {
        text: 'ACTIONS',
        value: 'actions',
        align: 'center',
        sortable: false,
      },
    ]

    const onUserUpdated = () => {
      emit('user-updated')
    }

    const synchronizing = ref(false)

    const sync = async user => {
      synchronizing.value = true

      try {
        await userService.syncUserToCore(user.id)

        useNotifySuccess({
          content: 'User has been synced successfully.',
        })
      } catch (error) {
        console.error(error)
      } finally {
        synchronizing.value = false
      }
    }

    watch(
      () => options.value,
      newValue => {
        if (!isPageManualChange.value) {
          emit('options-updated', newValue)
        }
      },
    )

    return {
      t,

      options,

      columns,
      headerprops,

      formatDate,
      resetPage,

      onUserUpdated,
      sync,

      icons: {
        mdiSync,
        mdiDotsVertical,
        mdiPencilOutline,
      },
    }
  },
}
</script>